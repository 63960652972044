

import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'FormTitle'
})
export default class FormTitle extends Vue {
  @Prop() title!: string
  @Prop({ default: null }) description!: string | null
}
